import { Coordinates } from '../modules/restaurant/restaurant.interface'

/**
 * Converts degrees to radians
 * @param value
 * @returns radians
 */
function toRadians(value: number) {
  return (value * Math.PI) / 180
}

/**
 * Calculates the distance between 2 coordinates
 * @param from
 * @param to
 * @returns distance in meters
 */
export function calculateDistance(from: Coordinates, to: Coordinates): number {
  const R = 6371 // km
  const dLat = toRadians(from[0] - to[0])
  const dLon = toRadians(from[1] - to[1])
  const lat1 = toRadians(from[0])
  const lat2 = toRadians(to[0])

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c

  return d
}
