export function isRestaurantClosed(openingHours) {
  var daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  var currentDayOfWeek = daysOfWeek[new Date().getDay()];
  var currentHours = new Date().getHours() * 3600;
  var currentMinutes = new Date().getMinutes() * 60;
  var currentTime = currentHours + currentMinutes;
  var isBetweenHoursIndex = openingHours[currentDayOfWeek].hours.findIndex(function (hour) {
    return currentTime >= hour.startAt && currentTime <= hour.endAt;
  });
  return !openingHours[currentDayOfWeek].isActive || isBetweenHoursIndex === -1;
}