import { BaseAPI } from './base'

export enum ProductGalleryLayout {
  Picture4Products = 'picture4Products',
  NoPicture4Products = 'noPicture4Products',
  Picture2Products = 'picture2Products',
}

export const landingPageMock = {
  regions: {
    es: {
      header: {
        links: [
          {
            url: 'https://www.google.fr',
            text: {
              fr: 'Notre Carte',
              es: 'Nuestra Carta',
              en: 'Our menu',
              nl: 'Notre Carte NL',
              pt: 'Notre Carte PT',
            },
          },
          {
            url: 'https://www.mozilla.org',
            text: {
              fr: 'Nos restaurants',
              es: 'Nuestros restaurantes',
              en: 'Our restaurants',
              nl: 'Nos restaurants NL',
              pt: 'Nos restaurants PT',
            },
          },
          {
            url: 'https://www.google.com',
            text: {
              fr: 'La fidélité',
              es: 'Fidelización',
              en: 'Loyalty',
              nl: 'La fidélité NL',
              pt: 'La fidélité PT',
            },
          },
          {
            url: 'https://www.google.es',
            text: {
              fr: 'À propos',
              es: 'Contáctenos',
              en: 'Contact us',
              nl: 'Nos restaurants NL',
              pt: 'Nos restaurants PT',
            },
          },
        ],
        base: {
          colors: {
            backgroundColorOverride: undefined,
            colorOverride: undefined,
          },
          navigationButton: {
            backgroundColorOverride: undefined,
            colorOverride: undefined,
          },
        },
        sticky: {
          colors: {
            backgroundColorOverride: '#fefefe00',
            colorOverride: '#afaf00',
          },
          navigationButton: {
            backgroundColorOverride: '#ff0000',
            colorOverride: '#fecfec',
          },
        },
        top: {
          enabled: true,
          // enabled: false,
          main: {
            backgroundColorOverride: '#ed9780',
            colorOverride: 'white',
            text: {
              fr: 'This is an ad FR',
              es: 'This is an ad ES',
              en: 'This is an ad EN',
              nl: 'This is an ad NL',
              pt: 'This is an ad PT',
            },
          },
          cta: {
            text: {
              fr: 'Click FR',
              es: 'Click ES',
              en: 'Click EN',
              nl: 'Click NL',
              pt: 'Click PT',
            },
            url: 'https://www.google.es',
            backgroundColorOverride: '#ed1780',
            colorOverride: 'black',
          },
        },
      },
      content: [
        {
          option: 'quickOrder',
          config: {
            title: {
              text: {
                fr: 'Bienvenue chez Staging',
                es: 'Bienvenido a Staging',
                en: 'Welcome to Staging',
                nl: 'Title NL',
                pt: 'Title PT',
              },
              colorOverride: '#fff',
            },
            subtitle: {
              text: {
                fr: 'Commandez vos Staging préférés en Click & Collect ou en livraison',
                es: 'Pida sus Staging preferidos en Click & Recogida o con envío',
                en: 'Order your favourite Staging in Click & Collect or with delivery ',
                nl: 'Subtitle NL',
                pt: 'Subtitle PT',
              },
              colorOverride: '#bfbfbf',
            },
            background: {
              option: 'color',
              config: {
                color: '#2f2f2f',
              },
            },
          },
        },
        {
          option: 'quickOrder',
          config: {
            title: {
              text: {
                fr: 'Bienvenue chez Staging 2',
                es: 'Bienvenido a Staging 2',
                en: 'Welcome to Staging 2',
                nl: 'Title NL 2',
                pt: 'Title PT 2',
              },
              colorOverride: undefined,
            },
            subtitle: {
              text: {
                fr: 'Commandez vos Staging préférés en Click & Collect ou en livraison 2',
                es: 'Pida sus Staging preferidos en Click & Recogida o con envío 2',
                en: 'Order your favourite Staging in Click & Collect or with delivery 2',
                nl: 'Subtitle NL 2',
                pt: 'Subtitle PT 2',
              },
              colorOverride: undefined,
            },
            background: {
              option: 'image',
              config: {
                desktop: {
                  fr: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                  es: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                  en: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                  nl: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                  pt: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                },
                mobile: {
                  fr: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                  es: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                  en: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                  nl: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                  pt: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                },
              },
            },
          },
        },
        {
          option: 'bannersSlider',
          config: {
            title: {
              text: {
                fr: 'EN CE MOMENT !',
                es: '¡Ahora mismo!',
                en: 'At this time!',
                nl: 'Banner Title NL 2',
                pt: 'Banner Title PT 2',
              },
              colorOverride: undefined,
            },
            subtitle: {
              text: {
                fr: 'Découvrez les actualités à ne pas manquer.',
                es: 'Descubra las novedades para comer.',
                en: 'Discover the new dishes',
                nl: 'Banner Subtitle NL 2',
                pt: 'Banner Subtitle PT 2',
              },
              colorOverride: undefined,
            },
          },
        },
        {
          option: 'bannersSlider',
          config: {
            title: {
              text: {
                fr: 'EN CE MOMENT !',
                es: '¡Ahora mismo!',
                en: 'At this time!',
                nl: 'Banner Title NL 2',
                pt: 'Banner Title PT 2',
              },
              colorOverride: 'red',
            },
            subtitle: {
              text: {
                fr: 'Découvrez les actualités à ne pas manquer.',
                es: 'Descubra las novedades para comer.',
                en: 'Discover the new dishes',
                nl: 'Banner Subtitle NL 2',
                pt: 'Banner Subtitle PT 2',
              },
              colorOverride: 'green',
            },
          },
        },
        {
          option: 'image',
          config: {
            desktop: {
              fr: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
              es: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
              en: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
              nl: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
              pt: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
            },
            mobile: {
              fr: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
              es: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
              en: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
              nl: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
              pt: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
            },
          },
        },
        {
          option: 'productGallery',
          config: {
            title: {
              text: {
                fr: 'EN CE MOMENT !',
                es: '¡Ahora mismo!',
                en: 'At this time!',
                nl: 'Banner Title NL 2',
                pt: 'Banner Title PT 2',
              },
              colorOverride: 'red',
            },
            subtitle: {
              text: {
                fr: 'Découvrez les actualités à ne pas manquer.',
                es: 'Descubra las novedades para comer.',
                en: 'Discover the new dishes',
                nl: 'Banner Subtitle NL 2',
                pt: 'Banner Subtitle PT 2',
              },
              colorOverride: 'yellow',
            },
            backgroundImage: {
              desktop: {
                fr: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                es: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                en: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                nl: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                pt: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
              },
              mobile: {
                fr: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                es: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                en: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                nl: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                pt: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
              },
            },
            cta: {
              colorOverride: 'green',
              backgroundColorOverride: 'pink',
              url: 'https://www.google.org',
              text: {
                fr: 'Découvrez les actualités à ne pas manquer.',
                es: 'Descubra las novedades para comer.',
                en: 'Discover the new dishes',
                nl: 'Banner Subtitle NL 2',
                pt: 'Banner Subtitle PT 2',
              },
            },
            layout: {
              option: 'picture4Products', // "picture2Products", "noPicture4Products",
              picture: {
                // null
                fr: 'https://belorder.com/wp-content/uploads/2020/11/bimo-luki-mwHzM7qB7vk-unsplash-1536x864.jpg',
                es: 'https://belorder.com/wp-content/uploads/2020/11/bimo-luki-mwHzM7qB7vk-unsplash-1536x864.jpg',
                en: 'https://belorder.com/wp-content/uploads/2020/11/bimo-luki-mwHzM7qB7vk-unsplash-1536x864.jpg',
                nl: 'https://belorder.com/wp-content/uploads/2020/11/bimo-luki-mwHzM7qB7vk-unsplash-1536x864.jpg',
                pt: 'https://belorder.com/wp-content/uploads/2020/11/bimo-luki-mwHzM7qB7vk-unsplash-1536x864.jpg',
              },
              products: [
                {
                  // ProductResponse structure? Maybe a partial with the necessary data?
                  id: '1',
                  name: {
                    fr: 'American',
                    es: 'Americana',
                    en: 'American',
                    nl: 'American NL',
                    pt: 'American PT',
                  },
                  price: '1150',
                  image: 'https://pokawa-dev.s3.eu-central-1.amazonaws.com/1657787396411.jpeg',
                },
                {
                  id: '2',
                  name: {
                    fr: 'Buffalo',
                    es: 'Búfalo',
                    en: 'Buffalo',
                    nl: 'Buffalo NL',
                    pt: 'Buffalo PT',
                  },
                  price: '650',
                  image: 'https://pokawa-dev.s3.eu-central-1.amazonaws.com/1659360960979.jpg',
                },
                {
                  id: '3',
                  name: {
                    fr: 'Algérienne',
                    es: 'Algeriana',
                    en: 'Algerian',
                    nl: 'Algérienne NL',
                    pt: 'Algérienne PT',
                  },
                  price: '940',
                  image: 'https://pokawa-dev.s3.eu-central-1.amazonaws.com/1657787525966.jpeg',
                },
                {
                  id: '4',
                  name: {
                    fr: 'Schweppes mojito 33 cL',
                    es: 'Schweppes mojito 33 cL',
                    en: 'Schweppes mojito 33 cL',
                    nl: 'Schweppes mojito 33 cL',
                    pt: 'Schweppes mojito 33 cL',
                  },
                  price: '260',
                  image: 'https://pokawa-dev.s3.eu-central-1.amazonaws.com/1657787134625.jpeg',
                },
              ],
            },
          },
        },
        {
          option: 'productGallery',
          config: {
            title: {
              text: {
                fr: 'EN CE MOMENT !',
                es: '¡Ahora mismo!',
                en: 'At this time!',
                nl: 'Banner Title NL 2',
                pt: 'Banner Title PT 2',
              },
              colorOverride: undefined,
            },
            subtitle: {
              text: {
                fr: 'Découvrez les actualités à ne pas manquer.',
                es: 'Descubra las novedades para comer.',
                en: 'Discover the new dishes',
                nl: 'Banner Subtitle NL 2',
                pt: 'Banner Subtitle PT 2',
              },
              colorOverride: undefined,
            },
            backgroundImage: {
              desktop: {
                fr: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                es: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                en: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                nl: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                pt: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
              },
              mobile: {
                fr: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                es: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                en: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                nl: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                pt: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
              },
            },
            cta: {
              colorOverride: undefined,
              backgroundColorOverride: undefined,
              url: 'https://www.google.org',
              text: {
                fr: 'Découvrez les actualités à ne pas manquer.',
                es: 'Descubra las novedades para comer.',
                en: 'Discover the new dishes',
                nl: 'Banner Subtitle NL 2',
                pt: 'Banner Subtitle PT 2',
              },
            },
            layout: {
              option: 'noPicture4Products', // "picture2Products", "picture4Products",
              products: [
                {
                  // ProductResponse structure? Maybe a partial with the necessary data?
                  id: '1',
                  name: {
                    fr: 'American',
                    es: 'Americana',
                    en: 'American',
                    nl: 'American NL',
                    pt: 'American PT',
                  },
                  price: '1150',
                  image: 'https://pokawa-dev.s3.eu-central-1.amazonaws.com/1657787396411.jpeg',
                },
                {
                  id: '2',
                  name: {
                    fr: 'Buffalo',
                    es: 'Búfalo',
                    en: 'Buffalo',
                    nl: 'Buffalo NL',
                    pt: 'Buffalo PT',
                  },
                  price: '650',
                  image: 'https://pokawa-dev.s3.eu-central-1.amazonaws.com/1659360960979.jpg',
                },
                {
                  id: '3',
                  name: {
                    fr: 'Algérienne',
                    es: 'Algeriana',
                    en: 'Algerian',
                    nl: 'Algérienne NL',
                    pt: 'Algérienne PT',
                  },
                  price: '940',
                  image: 'https://pokawa-dev.s3.eu-central-1.amazonaws.com/1657787525966.jpeg',
                },
                {
                  id: '4',
                  name: {
                    fr: 'Schweppes mojito 33 cL',
                    es: 'Schweppes mojito 33 cL',
                    en: 'Schweppes mojito 33 cL',
                    nl: 'Schweppes mojito 33 cL',
                    pt: 'Schweppes mojito 33 cL',
                  },
                  price: '260',
                  image: 'https://pokawa-dev.s3.eu-central-1.amazonaws.com/1657787134625.jpeg',
                },
              ],
            },
          },
        },
        {
          option: 'productGallery',
          config: {
            title: {
              text: {
                fr: 'EN CE MOMENT !',
                es: '¡Ahora mismo!',
                en: 'At this time!',
                nl: 'Banner Title NL 2',
                pt: 'Banner Title PT 2',
              },
              colorOverride: undefined,
            },
            subtitle: {
              text: {
                fr: 'Découvrez les actualités à ne pas manquer.',
                es: 'Descubra las novedades para comer.',
                en: 'Discover the new dishes',
                nl: 'Banner Subtitle NL 2',
                pt: 'Banner Subtitle PT 2',
              },
              colorOverride: undefined,
            },
            backgroundImage: {
              desktop: {
                fr: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                es: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                en: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                nl: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                pt: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
              },
              mobile: {
                fr: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                es: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                en: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                nl: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
                pt: 'https://belorder.com/wp-content/uploads/2020/11/Belorder-intégration-caisses.jpg',
              },
            },
            cta: {
              colorOverride: undefined,
              backgroundColorOverride: undefined,
              url: 'https://www.google.org',
              text: {
                fr: 'Découvrez les actualités à ne pas manquer.',
                es: 'Descubra las novedades para comer.',
                en: 'Discover the new dishes',
                nl: 'Banner Subtitle NL 2',
                pt: 'Banner Subtitle PT 2',
              },
            },
            layout: {
              option: 'picture2Products', // "picture4Products", "picture2Products", "noPicture4Products",
              picture: {
                // null
                fr: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                es: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                en: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                nl: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
                pt: 'https://belorder.com/wp-content/uploads/2020/12/Belorder.jpg',
              },
              products: [
                {
                  // ProductResponse structure? Maybe a partial with the necessary data?
                  id: '1',
                  name: {
                    fr: 'American',
                    es: 'Americana',
                    en: 'American',
                    nl: 'American NL',
                    pt: 'American PT',
                  },
                  price: '1150',
                  image: 'https://pokawa-dev.s3.eu-central-1.amazonaws.com/1657787396411.jpeg',
                },
                {
                  id: '2',
                  name: {
                    fr: 'Buffalo',
                    es: 'Búfalo',
                    en: 'Buffalo',
                    nl: 'Buffalo NL',
                    pt: 'Buffalo PT',
                  },
                  price: '650',
                  image: 'https://pokawa-dev.s3.eu-central-1.amazonaws.com/1659360960979.jpg',
                },
              ],
            },
          },
        },
        {
          option: 'instagramFeed',
          config: {
            account: {
              fr: 'belorder_fr',
              es: 'belorder_fr',
              en: 'belorder_fr',
              nl: 'belorder_fr',
              pt: 'belorder_fr',
            },
            cta: {
              title: {
                fr: 'Suivez-nous sur instagram',
                es: 'Síguenos en instagram',
                en: 'Follow us in instagram',
                nl: 'Follow NL',
                pt: 'Follow PT',
              },
              color: '#aedac2',
            },
          },
        },
      ],
    },
  },
} as const

export class LandingPageApi extends BaseAPI {
  /**
   *
   * @summary Gets the current landing page.
   * @memberof LandingPageApi
   */
  public getCurrentLandingPage() {
    return landingPageMock
  }
}
