import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import moment from 'moment'

import { Config } from '../interfaces/config.interface'

const MINUTES_CACHE = 1

interface CacheItem {
  createdAt: Date
  data: any
  status: number
  statusText: string
  headers: AxiosResponse['headers']
}

const cache: Record<string, CacheItem> = {}

/**
 * Generates an http client with the given configuration
 * @param config
 * @returns http client
 */
export function generateHttpClient(config?: Config) {
  const timeout = config?.api.timeout ?? 15000
  const http = axios.create({
    baseURL: config?.api.baseUrl,
    timeout,
  })

  http.interceptors.request.use((axiosConfig: AxiosRequestConfig) => {
    // console.log('making request', {
    //   url: `${axiosConfig.method}: ${axiosConfig.url}`,
    //   params: JSON.stringify(axiosConfig.params, undefined, ' '),
    //   data: JSON.stringify(axiosConfig.data, undefined, ' '),
    // })

    // Add token
    if (!axiosConfig.params) {
      axiosConfig.params = {}
    }

    return axiosConfig
  })

  http.interceptors.request.use((axiosConfig: AxiosRequestConfig) => {
    if (axiosConfig.method !== 'get' || !axiosConfig.url) {
      return axiosConfig
    }

    const url = axiosConfig.url
    const cachedResult = cache[url]

    if (!cachedResult) {
      return axiosConfig
    }

    const cacheExpiration = moment(cachedResult.createdAt).add(MINUTES_CACHE, 'minutes')
    if (moment().isBefore(cacheExpiration)) {
      axiosConfig.data = cachedResult.data

      // Set the axiosConfig adapter to send the cached response and prevent the axiosConfig from actually running
      axiosConfig.adapter = () => {
        return Promise.resolve({
          data: cachedResult.data,
          status: cachedResult.status,
          statusText: cachedResult.statusText,
          headers: cachedResult.headers,
          config: axiosConfig,
          request: axiosConfig,
        })
      }
    }
    return axiosConfig
  })

  http.interceptors.response.use((response) => {
    const isCacheable =
      response.config?.params?.__cache === true && response.config?.method === 'get'

    if (!isCacheable) {
      return response
    }

    if (!response.config?.url) {
      return response
    }

    const url = response.config.url

    cache[url] = {
      data: response.data,
      createdAt: new Date(),
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
    }

    return response
  })

  // Fix for timeouts in android
  http.interceptors.request.use((config) => {
    config.cancelToken = new axios.CancelToken((cancelRequest) => {
      setTimeout(
        () => cancelRequest(`timeout for request: [${config.method}] ${config.url}`),
        config.timeout ?? timeout,
      )
    })
    return config
  })

  http.interceptors.response.use(
    (res: AxiosResponse) => {
      // console.log('http response', {
      //   url: res.config.url,
      //   data: JSON.stringify(res.data, undefined, ' '),
      // })

      return res
    },
    (error) => {
      console.warn('http error', {
        message: error.message,
        request: error.request?._url,
        data: error.data,
        response: error.response?.data,
      })

      return Promise.reject(error)
    },
  )

  return http
}
