import moment from 'moment'

import { api } from '../../api/clients'
import {
  CatalogResponse,
  DailyActiveHoursResponse,
  OrderPlatform,
  OrderType,
  RestaurantPublicResponse,
} from '../../client'
import { calculateDistance } from '../../utils/geolocation'
import { Coordinates, DayOfWeek, TimeSlot } from './restaurant.interface'

const DAY_OF_WEEK_MAP: Record<number, DayOfWeek> = {
  1: DayOfWeek.Monday,
  2: DayOfWeek.Tuesday,
  3: DayOfWeek.Wednesday,
  4: DayOfWeek.Thursday,
  5: DayOfWeek.Friday,
  6: DayOfWeek.Saturday,
  7: DayOfWeek.Sunday,
}

/**
 * Gets all the time slots for a given product.
 * Depending on the type, the calculation is different.
 *
 * @param restaurant
 * @param orderType
 * @returns time slots
 */
export function getRestaurantTimeSlots(
  restaurant?: Pick<
    RestaurantPublicResponse,
    'preparationDuration' | 'delivery' | 'pickUp' | 'eatIn'
  >,
  orderType?: OrderType,
): TimeSlot[] {
  if (!restaurant || !orderType) {
    return []
  }
  if (orderType === OrderType.Delivery) {
    return getRestaurantDeliveryTimeSlots(restaurant)
  }
  if (orderType === OrderType.PickUp) {
    return getRestaurantPickUpTimeSlots(restaurant)
  }
  if (orderType === OrderType.EatIn) {
    return getRestaurantEatInTimeSlots(restaurant)
  }

  return []
}

export function createSlotForCurrentTime(
  restaurant: Pick<RestaurantPublicResponse, 'preparationDuration'>,
): TimeSlot {
  const now = moment().add(restaurant.preparationDuration, 'minutes')

  return {
    id: now.unix(),
    from: now.toDate(),
    to: now.clone().add(15, 'minutes').toDate(),
    isNow: true,
  }
}

/**
 * Gets whether the given restaurant is open for the given order type
 * @param restaurant
 * @param orderType
 * @returns whether the restaurant is open
 */
export function getRestaurantIsOpen(
  restaurant: RestaurantPublicResponse,
  orderType: OrderType,
): boolean {
  return getRestaurantTimeSlots(restaurant, orderType)?.length > 0
}

/**
 * Gets time slots for delivery type.
 *
 * @param restaurant
 * @returns time slots
 */
export function getRestaurantDeliveryTimeSlots(
  restaurant: Pick<RestaurantPublicResponse, 'preparationDuration' | 'delivery'>,
): TimeSlot[] {
  return extractTimeSlotsFromHours(restaurant, restaurant.delivery.hours)
}

/**
 * Gets time slots for pick up type.
 *
 * @param restaurant
 * @returns time slots
 */
export function getRestaurantPickUpTimeSlots(
  restaurant: Pick<RestaurantPublicResponse, 'preparationDuration' | 'pickUp'>,
): TimeSlot[] {
  return extractTimeSlotsFromHours(restaurant, restaurant.pickUp.hours)
}

/**
 * Gets time slots for eat in type.
 *
 * @param restaurant
 * @returns time slots
 */
export function getRestaurantEatInTimeSlots(
  restaurant: Pick<RestaurantPublicResponse, 'preparationDuration' | 'eatIn'>,
): TimeSlot[] {
  return extractTimeSlotsFromHours(restaurant, restaurant.eatIn.hours)
}

/**
 * Extracts the time slots from open-close hours
 *
 * @param restaurant
 * @param hours
 * @returns time slots
 */
function extractTimeSlotsFromHours(
  restaurant: Pick<RestaurantPublicResponse, 'preparationDuration'>,
  hours: DailyActiveHoursResponse,
): TimeSlot[] {
  const dayOfWeek = DAY_OF_WEEK_MAP[moment().isoWeekday()]

  const day = hours?.[dayOfWeek]
  if (!day?.isActive) {
    return []
  }
  const slots = day?.hours
  if (!slots) {
    return []
  }

  const now = moment().add(restaurant.preparationDuration, 'minutes')
  return slots.flatMap((slot) => {
    const rowSlots: TimeSlot[] = []
    let current = moment().startOf('day').add(slot.startAt, 'seconds')
    const end = moment()
      .startOf('day')
      .add(slot.endAt, 'seconds')
      .subtract(restaurant.preparationDuration, 'minutes')
    while (current.isSameOrBefore(end)) {
      const next = current.clone().add(15, 'minutes')
      if (next.isSameOrAfter(now)) {
        const isNow = now.isAfter(current)

        rowSlots.push({
          id: current.unix(),
          from: current.toDate(),
          to: next.toDate(),
          isNow,
        })
      }

      current = next
    }

    return rowSlots
  })
}

/**
 * Returns the order fee in a restaurant for a given order type.
 *
 * @param restaurant
 * @param type
 */
export function getRestaurantOrderTypeFee(
  restaurant: RestaurantPublicResponse,
  type: OrderType,
): number {
  if (type === OrderType.Delivery) {
    return restaurant.delivery?.fee ?? 0
  }
  if (type === OrderType.PickUp) {
    return restaurant.pickUp?.fee ?? 0
  }
  if (type === OrderType.EatIn) {
    return restaurant.eatIn?.fee ?? 0
  }

  return 0
}

export async function getRestaurantCatalogs(
  restaurant: RestaurantPublicResponse,
  platform: OrderPlatform,
): Promise<Partial<Record<OrderType, CatalogResponse>>> {
  const result: Partial<Record<OrderType, CatalogResponse>> = {}

  if (restaurant.pickUp.isEnabled) {
    result[OrderType.PickUp] = await getRestaurantCatalog(restaurant.id, OrderType.PickUp, platform)
  }
  if (restaurant.delivery.isEnabled) {
    result[OrderType.Delivery] = await getRestaurantCatalog(
      restaurant.id,
      OrderType.Delivery,
      platform,
    )
  }
  if (restaurant.eatIn.isEnabled) {
    result[OrderType.EatIn] = await getRestaurantCatalog(restaurant.id, OrderType.EatIn, platform)
  }

  return result
}

export async function getRestaurantCatalog(
  restaurantId: string,
  orderType: OrderType,
  platform: OrderPlatform,
): Promise<CatalogResponse | undefined> {
  try {
    const result = await api.restaurants.getRestaurantCatalog(
      { restaurantId, orderType, platform },
      { params: { __cache: true } },
    )

    return result.data
  } catch (error) {
    console.warn('Restaurant has no catalog published')
  }
}

/**
 * Sorts restaurants by distance
 * - Closed restaurants go to the bottom
 * - If the distance is not available, they will be sorted alphabetically
 * @param restaurants
 * @param coordinates
 * @returns sorted restaurants
 */
export function sortRestaurants(
  restaurants: RestaurantPublicResponse[],
  orderType: OrderType,
  coordinates?: Coordinates,
): RestaurantPublicResponse[] {
  return restaurants.sort((a, b) => {
    const restaurantAIsOpen = getRestaurantIsOpen(a, orderType)
    const restaurantBIsOpen = getRestaurantIsOpen(b, orderType)
    if (restaurantAIsOpen && !restaurantBIsOpen) {
      return -1
    }
    if (!restaurantAIsOpen && restaurantBIsOpen) {
      return 1
    }

    if (coordinates) {
      return (
        calculateDistance(a.address.coordinates as Coordinates, coordinates) -
        calculateDistance(b.address.coordinates as Coordinates, coordinates)
      )
    }

    return a.name.localeCompare(b.name)
  })
}

/**
 * Validates the given due date in the restaurant.
 * Moves if the due date is already before the time.
 * @param dueDate
 * @param restaurant
 * @param platform
 * @returns validated date in ISO format
 */
export function validateDueDateForRestaurant(
  dueDate: Date,
  platform: OrderPlatform,
  restaurant?: RestaurantPublicResponse,
): string {
  // Always send the current date in the kiosks
  if (platform === OrderPlatform.Kiosk) {
    return moment().toISOString()
  }

  const momentDate = moment(dueDate)

  return momentDate.isBefore(new Date())
    ? moment()
        .add(restaurant?.preparationDuration ?? 15, 'minutes')
        .toISOString()
    : momentDate.toISOString()
}
