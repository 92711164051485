import { AxiosResponse } from 'axios'

import {
  AuthApi,
  CartsApi,
  CompaniesApi,
  CompanyRegionsApi,
  Configuration,
  ConnectApi,
  DiscountsApi,
  EdenredApi,
  FidelityProgramApi,
  KiosksApi,
  OrdersApi,
  PaymentsApi,
  RestaurantsApi,
} from '../client'
import { LandingPageApi } from '../client/mock'
import { Config } from '../interfaces/config.interface'
import { generateHttpClient } from './http'

const defaultHttp = generateHttpClient()

const API_CLIENTS = {
  auth: AuthApi,
  connect: ConnectApi,
  companies: CompaniesApi,
  companyRegions: CompanyRegionsApi,
  landingPages: LandingPageApi,
  restaurants: RestaurantsApi,
  discounts: DiscountsApi,
  orders: OrdersApi,
  carts: CartsApi,
  payments: PaymentsApi,
  edenred: EdenredApi,
  kiosks: KiosksApi,
  fidelityProgram: FidelityProgramApi,
}

type Apis = {
  [K in keyof typeof API_CLIENTS]: InstanceType<typeof API_CLIENTS[K]>
}

class ClientsManager {
  protected apis = Object.entries(API_CLIENTS).reduce((result, [name, ApiClass]) => {
    result[name as keyof typeof API_CLIENTS] = new ApiClass(
      undefined,
      undefined,
      defaultHttp,
    ) as any

    return result
  }, {} as Apis)

  /**
   * Initializes the clients using a configuration and JWT.
   * @param config
   * @param jwt
   * @param onUnauthorized fn to call when a request ends in non
   * authenticated response
   */
  init(config: Config, jwt?: string, onUnauthorized?: () => void) {
    const http = generateHttpClient(config)

    http.interceptors.request.use((httpConfig) => {
      if (!httpConfig.headers) {
        httpConfig.headers = {}
      }

      httpConfig.headers['X-PLATFORM'] = config.platformData.platform
      return httpConfig
    })

    if (jwt) {
      http.interceptors.response.use(
        (value: AxiosResponse) => value,
        (error: any) => {
          if (jwt && onUnauthorized && error?.response?.status === 401) {
            onUnauthorized()
          }

          throw error
        },
      )
    }

    const accessToken = jwt
    const apiKey = config.api.companyToken

    this.apis = Object.entries(API_CLIENTS).reduce((result, [name, ApiClass]) => {
      result[name as keyof typeof API_CLIENTS] = new ApiClass(
        new Configuration({ accessToken, apiKey }),
        config?.api.baseUrl,
        http,
      ) as any

      return result
    }, {} as Apis)
  }

  get auth() {
    return this.apis.auth
  }

  get connect() {
    return this.apis.connect
  }

  get companies() {
    return this.apis.companies
  }

  get companyRegions() {
    return this.apis.companyRegions
  }

  get restaurants() {
    return this.apis.restaurants
  }

  get discounts() {
    return this.apis.discounts
  }

  get orders() {
    return this.apis.orders
  }

  get carts() {
    return this.apis.carts
  }

  get payments() {
    return this.apis.payments
  }

  get edenred() {
    return this.apis.edenred
  }

  get kiosks() {
    return this.apis.kiosks
  }

  get fidelity() {
    return this.apis.fidelityProgram
  }

  get landingPages() {
    return this.apis.landingPages
  }
}

export const api = new ClientsManager()
